import React, { useState, useRef } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import IntakeNavbar from "../../Components/IntakeNavbar/IntakeNavbar";

const EventsFeeder = () => {
  const [formData, setFormData] = useState({
    BA_Category: "",
    BA_Status: "",
    BA_Priority: "",
    EV_Category: "",
    EV_Title: "",
    EV_Lead: "",
    EV_Description: "",
    EV_StartDate: "",
    EV_EndDate: "",
    EV_Attendees: "",
    EV_RegistrationLink: "",
    EV_City: "",
    EV_State: "",
    EV_Country: "",
    EV_Postalcode: "",
    EV_VenueName: "",
    EV_Organizer: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithImage = new FormData();
    for (const key in formData) {
      formDataWithImage.append(key, formData[key]);
    }

    if (imageFile) {
      formDataWithImage.append("EV_Image", imageFile);
    }

    try {
      const response = await axios.post(
        "https://www.bharatabroad.com/api/events",
        formDataWithImage,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log(response.data);
      setFormData({
        BA_Category: "",
        BA_Status: "",
        BA_Priority: "",
        EV_Category: "",
        EV_Title: "",
        EV_Lead: "",
        EV_Description: "",
        EV_StartDate: "",
        EV_EndDate: "",
        EV_Attendees: "",
        EV_RegistrationLink: "",
        EV_City: "",
        EV_State: "",
        EV_Country: "",
        EV_Postalcode: "",
        EV_VenueName: "",
        EV_Organizer: "",
      });
      setImageFile(null);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      <IntakeNavbar />
      <Container className="my-5">
        <h2 className="text-center text-primary mb-4">Add Event</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="BA_Category">
                <Form.Label>BA Category</Form.Label>
                <Form.Control
                  type="text"
                  name="BA_Category"
                  value={formData.BA_Category}
                  onChange={handleChange}
                  placeholder="Enter BA Category"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="BA_Status">
                <Form.Label>BA Status</Form.Label>
                <Form.Control
                  type="text"
                  name="BA_Status"
                  value={formData.BA_Status}
                  onChange={handleChange}
                  placeholder="Enter BA Status"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="BA_Priority">
                <Form.Label>BA Priority</Form.Label>
                <Form.Control
                  type="number"
                  name="BA_Priority"
                  value={formData.BA_Priority}
                  onChange={handleChange}
                  placeholder="Enter BA Priority"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="EV_Category">
                <Form.Label>Event Category</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_Category"
                  value={formData.EV_Category}
                  onChange={handleChange}
                  placeholder="Enter Event Category"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="EV_Title">
                <Form.Label>Event Title</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_Title"
                  value={formData.EV_Title}
                  onChange={handleChange}
                  placeholder="Enter Event Title"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="EV_Lead">
                <Form.Label>Event Lead</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_Lead"
                  value={formData.EV_Lead}
                  onChange={handleChange}
                  placeholder="Enter Event Lead"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="EV_Image">
                <Form.Label>Event Image</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="EV_Description">
            <Form.Label>Event Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="EV_Description"
              value={formData.EV_Description}
              onChange={handleChange}
              placeholder="Enter Event Description"
            />
          </Form.Group>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="EV_StartDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="EV_StartDate"
                  value={formData.EV_StartDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="EV_EndDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="EV_EndDate"
                  value={formData.EV_EndDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="EV_Attendees">
                <Form.Label>Number of Attendees</Form.Label>
                <Form.Control
                  type="number"
                  name="EV_Attendees"
                  value={formData.EV_Attendees}
                  onChange={handleChange}
                  placeholder="Enter Number of Attendees"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="EV_RegistrationLink">
                <Form.Label>Registration Link</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_RegistrationLink"
                  value={formData.EV_RegistrationLink}
                  onChange={handleChange}
                  placeholder="Enter Registration Link"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="EV_City">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_City"
                  value={formData.EV_City}
                  onChange={handleChange}
                  placeholder="Enter City"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="EV_State">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_State"
                  value={formData.EV_State}
                  onChange={handleChange}
                  placeholder="Enter State"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="EV_Country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_Country"
                  value={formData.EV_Country}
                  onChange={handleChange}
                  placeholder="Enter Country"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="EV_Postalcode">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_Postalcode"
                  value={formData.EV_Postalcode}
                  onChange={handleChange}
                  placeholder="Enter Postal Code"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="EV_VenueName">
                <Form.Label>Venue Name</Form.Label>
                <Form.Control
                  type="text"
                  name="EV_VenueName"
                  value={formData.EV_VenueName}
                  onChange={handleChange}
                  placeholder="Enter Venue Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="EV_Organizer">
            <Form.Label>Organizer</Form.Label>
            <Form.Control
              type="text"
              name="EV_Organizer"
              value={formData.EV_Organizer}
              onChange={handleChange}
              placeholder="Enter Organizer"
            />
          </Form.Group>
          <Row className="text-center">
            <Col>
              <Button type="submit" variant="primary" className="px-5">
                Add Events
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default EventsFeeder;
