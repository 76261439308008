import React, { useState, useRef } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import IntakeNavbar from "../../Components/IntakeNavbar/IntakeNavbar";

const HealthMasterFeeder = () => {
  const [formData, setFormData] = useState({
    HH_Title: "",
    HH_Lead: "",
    HH_Description: "",
    HH_Country: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithImage = new FormData();
    for (const key in formData) {
      formDataWithImage.append(key, formData[key]);
    }

    if (imageFile) {
      formDataWithImage.append("HH_Image", imageFile);
    }

    try {
      const response = await axios.post(
        "https://www.bharatabroad.com/api/health",
        formDataWithImage,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setFormData({
        HH_Title: "",
        HH_Lead: "",
        HH_Description: "",
        HH_Country: "",
      });
      setImageFile(null);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      <IntakeNavbar />
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col>
            <h2 className="text-center mb-4 text-success">Add Health Master</h2>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="HH_Title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="HH_Title"
                      value={formData.HH_Title}
                      onChange={handleChange}
                      placeholder="Enter title"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="HH_Lead">
                    <Form.Label>Lead</Form.Label>
                    <Form.Control
                      type="text"
                      name="HH_Lead"
                      value={formData.HH_Lead}
                      onChange={handleChange}
                      placeholder="Enter lead"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="HH_Image">
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="HH_Country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="HH_Country"
                      value={formData.HH_Country}
                      onChange={handleChange}
                      placeholder="Enter country"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="HH_Description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="HH_Description"
                      value={formData.HH_Description}
                      onChange={handleChange}
                      placeholder="Enter description"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Button type="submit" variant="success" className="px-5">
                    Add Health Master
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HealthMasterFeeder;
